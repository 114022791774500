import { Wrapper, Text, Content, MSWord } from './styles';
import { useDocument } from '../../../context/document-context';
import MSWordIcon from '../../icons/msword-icon';

import ModalTitle from '../modal-title';
import PaymentForm from './payment-form';
import { DocumentFailed } from './document-failed';
import { DocumentDone } from './document-done';
import { DocumentIsProcessing } from './document-is-processing';

import StripeHandler from './stripe-handler';

const PaymentModal: React.FC = () => {
  const { state, send } = useDocument();

  // default, when payment modal open and the payment is not completed
  let content = (
    <StripeHandler>
      <PaymentForm
        onSubmit={({ values, cardElement, stripe }) => {
          if (!stripe) {
            throw new Error(
              'Tried to capture payment without initializing Stripe Elements first. Please make sure there is an <Elements> provider component somewhere in the component tree.',
            );
          }
          // If we pass in the cardElement directly everything will freeze because XState doesn't support
          // DOM elements in events. So we pass in an function that the handler can use instead
          send({ type: 'CAPTURE_PAYMENT', values, getCardElement: () => cardElement, stripe });
        }}
        isCapturingPayment={state.hasTag('capturingPayment')}
      />
    </StripeHandler>
  );

  // if the payment is COMPLETED this will show instead of paymentForm
  if (state.hasTag('paymentCaptured')) {
    if (state.hasTag('documentIsProcessing')) {
      content = <DocumentIsProcessing />;
    } else if (state.hasTag('documentDone')) {
      content = (
        <DocumentDone
          downloadExpired={state.context.downloadExpired}
          downloadUrl={state.context.downloadUrl}
          downloadAvailableDays={state.context.downloadAvailableDays}
        />
      );
    } else if (state.hasTag('documentFailed')) {
      content = <DocumentFailed />;
    }
  }

  return (
    <>
      <ModalTitle>Download your Paperpal Edited Word file</ModalTitle>
      <Wrapper>
        <Content bg="primary">
          <MSWord>
            <MSWordIcon height={82} />
          </MSWord>
          <Text>
            Your Paperpal Edited Word file includes all our edits as <em>tracked changes</em> in
            your originally uploaded document.
          </Text>
          <Text fontWeight="500" color="main">
            All your formatting will be intact.
          </Text>
        </Content>
        <Content bg="secondary">{content}</Content>
      </Wrapper>
    </>
  );
};

export default PaymentModal;
